<template>
  <MRow :gutter="0">
    <MCol class="search-container">
      <div class="flex items-center h-100">
        <div v-if="value.length && allowSaveSearch" class="save-search">
          <SaveSearch
            :search="selectedSearch"
            :is-saved="!isDirty"
            @save-as="handleSaveSearch($event, true)"
            @save="handleSaveSearch"
          />
        </div>
        <div
          class="internal-search-box flex flex-wrap items-center justify-start px-1"
        >
          <ValueSelector
            v-for="(qualification, index) in value"
            :key="`${qualification.paramName}-${index}`"
            class="mx-1"
            :value="qualification"
            :module-name="moduleName"
            :available-asset-type="availableAssetType"
            :source="getSourceFromParam(qualification.paramName)"
            @remove="removeQualification(index)"
            @change="updateQualification($event, index)"
          />
          <div
            :class="{
              'flex-grow':
                !currentSelectedSource && !currentSelectedAdvancedSource,
            }"
            :style="{
              minWidth:
                !currentSelectedSource && !currentSelectedAdvancedSource
                  ? '200px'
                  : 'unset',
            }"
          >
            <ParamSelector
              v-if="!currentSelectedSource && !currentSelectedAdvancedSource"
              :key="paramSelectorKey"
              ref="paramSelector"
              :sources="availableSources"
              :search-bar-placeholder="searchBarPlaceholder"
              :allow-keywords="allowKeywords"
              @selected="sourceSelected"
              @advanced-search-param-selected="advancedSourceSelected"
            />
            <ValueSelector
              v-else-if="currentSelectedSource"
              :module-name="moduleName"
              :source="currentSelectedSource"
              :available-asset-type="availableAssetType"
              @cancel="sourceCancelled"
              @change="handleNewQualificationSelected"
            />
            <AdvancedValueSelector
              v-else-if="currentSelectedAdvancedSource"
              :module-name="moduleName"
              :source="currentSelectedAdvancedSource"
              :available-asset-type="availableAssetType"
              :transform-sources-fn="transformSources"
              @cancel="advancedSourceCancelled"
              @change="handleNewAdvancedQualificationSelected"
            />
          </div>
        </div>
        <FlotoDeleteBtn
          v-if="isDirty"
          :message="$t('confirm_reset_item', { item: $t('search') })"
          @confirm="resetSearch"
        >
          <div class="clear-search">
            <MIcon name="times" size="lg" />
          </div>
        </FlotoDeleteBtn>
      </div>
    </MCol>
  </MRow>
</template>

<script>
// import FindIndex from 'lodash/findIndex'
import { FormComputed } from '@state/modules/form'
import { PageMethods, PageComputed } from '@state/modules/page'
import { transformSearchCriteriasForServer } from '@data/search'
import { authComputed } from '@state/modules/auth'
import {
  customFieldsForAllAssetType,
  customFieldForAssetType,
} from '@modules/asset/helpers/asset-custom-fields'
import { getAssetTypeSystemName } from '@modules/asset/helpers/asset-type'
import { findValueObject, buildHierarchy } from '@data/recursive'
import { getSearchSources } from './sources'
import ParamSelector from './param-selector'
import ValueSelector from './value-selector'
import AdvancedValueSelector from './advanced-value-selector'
import SaveSearch from './save-search'
import { getFieldByRequesterAccess } from '@modules/support-portal/helpers/field-access'
import { flattenFields, useOnPortalEnabledModules } from '@data/form'

import { MspConfigComputed } from '@state/modules/msp-config/helpers'

export default {
  name: 'Search',
  components: {
    ParamSelector,
    ValueSelector,
    SaveSearch,
    AdvancedValueSelector,
  },
  inject: ['searchBarContext'],
  model: {
    event: 'change',
  },
  props: {
    moduleName: { type: String, required: true },
    onlyKeywords: { type: Boolean, default: false },
    shouldNotifyOnDefaultChange: { type: Boolean, default: false },
    excludeSearchParams: {
      type: Array,
      default() {
        return []
      },
    },
    additionSearchParams: {
      type: Array,
      default() {
        return []
      },
    },
    fixedSearchParams: {
      type: Array,
      default() {
        return []
      },
    },
    // eslint-disable-next-line
    allowSaveSearch: { type: Boolean, default: true },
    // eslint-disable-next-line
    allowKeywords: { type: Boolean, default: true },
    persistCriteria: { type: Boolean, default: false },
    defaultValue: {
      type: Array,
      default() {
        return []
      },
    },
    searchBarPlaceholder: {
      type: String,
      default() {
        if (this.onlyKeywords) {
          return this.$t('search_bar_placeholder_only_keywords')
        }
        if (!this.allowKeywords) {
          return this.$t('search_bar_placeholder_only_field')
        }
        return this.$t('search_bar_placeholder')
      },
    },
    availableAssetType: { type: [String, Array], default: undefined },
  },
  data() {
    return {
      allSources: [],
      paramSelectorKey: 1,
      isCriteriaChanged: false,
      currentSelectedSourceParam: undefined,
      currentSelectedAdvancedSourceParam: undefined,
      searchCriterias: this.defaultValue || [],
    }
  },
  computed: {
    ...authComputed,
    ...FormComputed,
    ...PageComputed,
    ...MspConfigComputed,
    // @TODO : If company field is added then we will have to refator this code
    filteredExcludeSearchParams() {
      if (!this.enableMsp) {
        return [...this.excludeSearchParams, 'companyId']
      }
      return this.excludeSearchParams
    },
    currentSelectedSource() {
      if (this.currentSelectedSourceParam) {
        return this.allSources.find(
          (x) => x.paramName === this.currentSelectedSourceParam
        )
      }
      return undefined
    },
    currentSelectedAdvancedSource() {
      if (this.currentSelectedAdvancedSourceParam) {
        return this.allSources.find(
          (x) => x.paramName === this.currentSelectedAdvancedSourceParam
        )
      }
      return undefined
    },
    isDirty() {
      if (this.persistCriteria) {
        return this[`${this.moduleName}SearchDirty`]
      } else {
        return this.isCriteriaChanged
      }
    },
    selectedSearch() {
      return this[`${this.moduleName}SelectedSearch`]
    },
    value: {
      get() {
        if (this.persistCriteria) {
          return this[`${this.moduleName}SearchCriterias`] || []
        } else {
          return this.searchCriterias || []
        }
      },
      set(data) {
        if (
          this[`${this.moduleName}UpdateSearchCriterias`] &&
          this.persistCriteria
        ) {
          return this[`${this.moduleName}UpdateSearchCriterias`](data)
        } else {
          this.searchCriterias = data.quals || data
        }
      },
    },
    usedParams() {
      if (this.value) {
        return this.value.map(({ paramName }) => paramName)
      } else {
        return []
      }
    },
    availableSources() {
      let searchParams = this.allSources
      if (this.isPortalLogin) {
        const sources = this.allSources.filter(
          (source) => source.showOnSupportPortal === true
        )
        let supportPortalSources = [...sources]
        if (useOnPortalEnabledModules.indexOf(this.moduleName) >= 0) {
          const flattenRequestPortalFields = flattenFields(
            this.requestPortalFields
          )
          let fields = getFieldByRequesterAccess(
            flattenRequestPortalFields,
            this.user
          )
          const fieldsParamName = fields.map((i) => {
            return i.paramName || String(i.id)
          })
          this.allSources.forEach((source) => {
            if (fieldsParamName.indexOf(source.paramName) >= 0) {
              supportPortalSources = [...supportPortalSources, source]
            }
          })
        }
        searchParams = supportPortalSources
      }
      return searchParams.filter((source) => {
        return (
          source.exclusive === false ||
          !this.usedParams.includes(source.paramName)
        )
      })
    },
    formModuleName() {
      if (
        [
          this.$constants.ASSET_HARDWARE,
          this.$constants.ASSET_SOFTWARE,
          this.$constants.ASSET_NON_IT,
          this.$constants.ASSET_CONSUMABLE,
        ].indexOf(this.moduleName) >= 0
      ) {
        return this.$constants.ASSET
      }
      return this.moduleName
    },
    customFields() {
      let fields =
        (this[`${this.formModuleName}Form`] &&
          this[`${this.formModuleName}Form`].fields) ||
        []
      if (this.formModuleName === this.$constants.ASSET) {
        const allTypefields = customFieldsForAllAssetType(fields)
        const assetType = findValueObject(
          this.searchBarContext.assetTypes,
          getAssetTypeSystemName[this.moduleName],
          'children',
          'systemName'
        )
        const assetTypeId = (assetType || {}).id
        const assetTypeFields = customFieldForAssetType(fields, [assetTypeId])
        fields = [...allTypefields, ...assetTypeFields]
      }
      if (this.formModuleName === this.$constants.PURCHASE) {
        fields = fields.filter(
          (f) => !f.attributes.costField || f.attributes.costField === 'false'
        )
      }
      return fields
    },
  },
  watch: {
    defaultValue(newValue) {
      this.resetSearch(this.shouldNotifyOnDefaultChange)
    },
    value(newValue) {
      const advanceSources = []
      newValue.forEach((v) => {
        if (v.qualContext && v.description) {
          const advancedSource = JSON.parse(v.description)
          if (advancedSource.customFieldType === 'dependent') {
            const o = buildHierarchy(advancedSource.options)
            advancedSource.options = o
          }
          if (
            !this.allSources.find(
              (s) => s.paramName === advancedSource.paramName
            )
          ) {
            advanceSources.push({
              ...(advancedSource || {}),
              hidden: true,
            })
          }
        }
      })
      this.allSources = [...this.allSources, ...(advanceSources || [])]
    },
  },
  created() {
    this.searchBarContext.getSupportData()
    if (this.onlyKeywords) {
      return
    }
    this.allSources = this.transformSources(this.availableSearchSources())
    const vuexOptionSources = this.allSources.filter((s) => s.vuexOptionKey)
    vuexOptionSources.forEach(({ vuexOptionKey }) => {
      const interpolatedKey = vuexOptionKey.replace(
        '{moduleName}',
        this.moduleName
      )
      // add dynamic watch on searchBarContext data change
      this.$watch(`searchBarContext.${interpolatedKey}`, () => {
        this.allSources = this.transformSources(this.availableSearchSources())
      })
    })
    this.$watch(`${this.formModuleName}Form`, () => {
      this.allSources = this.transformSources(this.availableSearchSources())
    })
    this.$watch(`searchBarContext.supportData`, () => {
      this.allSources = this.transformSources(this.availableSearchSources())
    })
  },
  methods: {
    ...PageMethods,
    availableSearchSources() {
      if (this.fixedSearchParams.length > 0) {
        return this.fixedSearchParams
      }
      return getSearchSources(
        this.moduleName,
        this.customFields,
        this.searchBarContext.supportData
      )
    },
    notifyParent() {
      this.isCriteriaChanged = true
      this.$emit(
        'change',
        transformSearchCriteriasForServer(this.moduleName, this.value)
      )
    },
    handleSaveSearch(search, saveAs = false) {
      const params = {
        ...search,
        qualification: this.value,
        id:
          this.selectedSearch && this.selectedSearch.canUpdate
            ? this.selectedSearch.id
            : undefined,
      }
      let methodName = `${this.moduleName}${
        params.id ? 'UpdateSearch' : 'AddSearch'
      }`
      if (saveAs) {
        methodName = `${this.moduleName}AddSearch`
      }
      if (this[methodName]) {
        this[methodName]({
          moduleName: this.moduleName,
          search: params,
        })
      }
    },
    resetSearch(notifyParent = true) {
      this.value = this.defaultValue
      if (notifyParent) {
        this.notifyParent()
      }
      this.$nextTick(() => {
        if (this.persistCriteria && this[`${this.moduleName}SetSearchDirty`]) {
          this[`${this.moduleName}SetSearchDirty`](false)
        }
        this.isCriteriaChanged = false
      })
    },
    handleNewQualificationSelected(v) {
      const finalCriteria = { ...v }
      // if boolean value and not touched then set to false
      if (v.valueType === 'boolean' && v.value === undefined) {
        finalCriteria.value = false
      }
      this.value = [...this.value, finalCriteria]
      this.currentSelectedSourceParam = undefined
      this.notifyParent()
    },
    handleNewAdvancedQualificationSelected(v) {
      this.handleNewQualificationSelected(v)
      this.currentSelectedAdvancedSourceParam = undefined
    },
    addKeyword(keyword) {
      this.handleNewQualificationSelected({
        keyword,
        value: keyword,
        valueType: 'keyword',
      })
    },
    updateQualification(qualification, index) {
      this.value = [
        ...this.value.slice(0, index),
        { ...qualification },
        ...this.value.slice(index + 1),
      ]
      this.notifyParent()
    },
    removeQualification(index) {
      this.value = [
        ...this.value.slice(0, index),
        ...this.value.slice(index + 1),
      ]
      this.notifyParent()
    },
    getSourceFromParam(paramName) {
      if (!paramName) {
        return {
          valueType: 'keyword',
        }
      }
      return this.allSources.find((s) => s.paramName === paramName) || {}
    },
    sourceSelected(source) {
      if (!source) {
        return
      }
      if (source.keyword) {
        this.value = [
          ...this.value,
          { ...source, value: source.keyword, valueType: 'keyword' },
        ]
        this.notifyParent()
        this.paramSelectorKey++
        return
      }
      this.currentSelectedSourceParam = source.paramName
    },
    advancedSourceSelected(source) {
      if (!source) {
        return
      }
      this.currentSelectedSourceParam = undefined
      this.currentSelectedAdvancedSourceParam = source.paramName
    },
    sourceCancelled() {
      this.currentSelectedSourceParam = undefined
    },
    advancedSourceCancelled() {
      this.currentSelectedAdvancedSourceParam = undefined
      this.currentSelectedSourceParam = undefined
    },
    transformSources(sources) {
      if (this.fixedSearchParams.length) {
        return this.fixedSearchParams.map((source) => {
          if (source.vuexOptionKey) {
            const vuexKey = source.vuexOptionKey.replace(
              '{moduleName}',
              this.moduleName
            )
            const vuexOptions =
              (this.searchBarContext && this.searchBarContext[vuexKey]) || []
            source.options = vuexOptions
          }
          return source
        })
      }
      return sources
        .filter(
          (source) =>
            this.filteredExcludeSearchParams.indexOf(source.paramName) === -1
        )
        .map((source) => {
          if (source.vuexOptionKey) {
            const vuexKey = source.vuexOptionKey.replace(
              '{moduleName}',
              this.moduleName
            )
            const vuexOptions =
              (this.searchBarContext && this.searchBarContext[vuexKey]) || []
            if (source.options) {
              source.options = [...source.options, ...vuexOptions]
            } else {
              source.options = vuexOptions
            }
            // remove achive condition here and add same condition on value inpute options only
            // source.options = source.options.filter((o) =>
            //   source.inputType !== 'dropdown' ? true : o.archived !== true
            // )
          }
          return source
        })
        .concat(this.additionSearchParams)
    },
  },
}
</script>

<style lang="less" scoped>
.search-container {
  min-height: 40px;
  background-color: var(--page-background-color);
  border-color: var(--border-color);
  border-width: 1px;

  @apply px-2 border-solid rounded;

  .internal-search-box {
    @apply flex-1;
    .@{ant-prefix}-tag {
      @apply my-1 flex;
    }
  }

  .save-search,
  .clear-search {
    @apply text-neutral-light;
  }
}
</style>
