<template>
  <MTag
    rounded
    variant="neutral-lighter"
    :closable="value.paramName !== 'removed' && value.paramName !== 'spam'"
    :style="
      value.description && value.description === 'hidden_search_value_selector'
        ? { display: 'none' }
        : {}
    "
    @close="handleRemove"
  >
    <template v-slot:confirm-title>
      {{
        $t('confirm_remove_item', { item: currentSource.name || value.value })
      }}
    </template>
    <div class="tag-content mx-1">
      <template v-if="currentSource.valueType === 'keyword'">
        <span :title="value.value">{{ value.value }}</span>
      </template>
      <template v-else-if="!currentSource.name">
        <span :title="anonymousParamName">{{ anonymousParamName }}</span>
      </template>
      <MPopover
        v-else
        ref="popover"
        :open="isOpen"
        :placement="popoverPlacment"
        transition-name="slide-up"
        overlay-class-name="picker-overlay"
        :default-open="!hasValue"
        destroy-tooltip-on-hide
        @hide="cancelSelection"
      >
        <template v-slot:trigger>
          <span :title="tagTitle" @click.stop="handleToggle">
            {{ currentSource.name }} {{ value.operator.name }}
            {{ selectedValue }}
            <template
              v-if="betweenOperators.indexOf((value.operator || {}).value) >= 0"
            >
              &nbsp; {{ $t('to') }} &nbsp;
              {{ selectedToValue }}
            </template>
          </span>
        </template>
        <ValueInput
          :type="currentSource.inputType"
          :value-type="currentSource.valueType"
          :placeholder="currentSource.name"
          :param-name="currentSource.paramName"
          :options="
            (sourceOptions || []).filter(
              (o) => o.archived !== true && !o.disabled
            )
          "
          :custom-field-type="currentSource.customFieldType"
          :additional-options="additionalOption"
          :operator="value.operator"
          :allow-decimal="currentSource.allowDecimal"
          :value="value.value"
          :to-value="value.toValue"
          :source-model="value.sourceModel"
          :operators="currentSource.operators"
          :module-name="moduleName"
          :available-asset-type="availableAssetType"
          :source="currentSource"
          @selected="handleValueSelected"
        />
      </MPopover>
    </div>
  </MTag>
</template>

<script>
import {
  unaryOperators,
  betweenOperators,
  durationOperators,
} from '@data/operator'
import { authComputed } from '@state/modules/auth'
import { flattenRecursive } from '@data/recursive'
import ValueInput from './value-input'
import Bytes from '@src/filters/bytes'
import Hertz from '@src/filters/hertz'

export default {
  name: 'ValueSelector',
  components: { ValueInput },
  inject: ['searchBarContext'],
  model: {
    event: 'change',
  },
  props: {
    source: { type: Object, required: true },
    moduleName: { type: String, required: true },
    value: {
      type: Object,
      default() {
        return {
          operator: {},
        }
      },
    },
    availableAssetType: { type: [String, Array], default: undefined },
  },
  data() {
    this.betweenOperators = betweenOperators
    return {
      isOpen: false,
    }
  },
  computed: {
    ...authComputed,
    anonymousParamName() {
      if (this.value.paramName === 'removed') {
        return `${this.$tc('archived')} ${this.$tc(this.moduleName, 2)}`
      }
      return this.$tc(this.value.paramName)
    },
    currentSource() {
      if (
        !Object.keys(this.source).length &&
        this.value &&
        this.value.qualContext &&
        this.value.description
      ) {
        return JSON.parse(this.value.description)
      }
      return this.source
    },
    popoverPlacment() {
      return this.isRtl ? 'bottomRight' : 'bottomLeft'
    },
    tagTitle() {
      if (betweenOperators.indexOf((this.value.operator || {}).value) >= 0) {
        return `${this.currentSource.name} ${this.value.operator.name} ${
          this.selectedValue
        } ${this.$t('to')} ${this.selectedToValue}`
      }
      return `${this.currentSource.name} ${this.value.operator.name} ${this.selectedValue}`
    },
    hasValue() {
      const operatorValue = (this.value.operator || {}).value
      if (operatorValue && unaryOperators.indexOf(operatorValue) >= 0) {
        return true
      }
      return this.value.value !== undefined
      // return Boolean(this.value.value)
    },
    selectedToValue() {
      if (!this.value.toValue && this.value.toValue !== 0) {
        return undefined
      }
      return this.transformValue(this.value.toValue)
    },
    selectedValue() {
      if (typeof this.value.value === 'boolean') {
        return this.transformValue(this.value.value)
      }
      if (!this.value.value && this.value.value !== 0) {
        return undefined
      }
      return this.transformValue(this.value.value)
    },
    additionalOption() {
      let option = []
      if (
        ['location', 'category', 'dependent', 'department'].indexOf(
          this.currentSource.inputType
        ) >= 0
      ) {
        option = [
          {
            id: 0,
            value: 0,
            label: this.$t('none'),
            name: this.$t('none'),
          },
        ]
      }
      if (['company'].indexOf(this.currentSource.inputType) >= 0) {
        option = [
          {
            id: 0,
            value: 0,
            key: 0,
            text: this.$t('none'),
            label: this.$t('none'),
            name: this.$t('none'),
          },
        ]
      }
      return option
    },
    sourceOptions() {
      let options = this.currentSource.options
      if (
        this.currentSource.customFieldType === 'dropdown' &&
        !this.currentSource.fieldReferenceType
      ) {
        options = [{ text: this.$t('none'), key: '', id: '' }, ...options]
      }
      const archivedOpions =
        this.searchBarContext.archivedOptionsMap[
          this.currentSource.vuexOptionKey
        ]
      if (archivedOpions) {
        options = [...options, ...(archivedOpions || [])]
      }
      if (
        this.currentSource.fieldDetailsOptions &&
        this.currentSource.fieldDetailsOptions.length
      ) {
        options = [
          ...options,
          ...this.currentSource.fieldDetailsOptions.map((o) => ({
            ...o,
            disabled: true,
          })),
        ]
      }
      return options
    },
  },
  watch: {
    'value.value': {
      immediate: true,
      handler(newValue, prevValue) {
        if (newValue && newValue !== prevValue) {
          if (this.currentSource.inputType === 'requester') {
            this.searchBarContext.getRequesters(newValue)
          }
          if (this.currentSource.inputType === 'product') {
            this.searchBarContext.getProducts(newValue)
          }
          if (this.currentSource.inputType === 'service_catalog') {
            this.searchBarContext.getSerciceCatalogs(newValue)
          }
          if (this.currentSource.inputType === 'vendor') {
            this.searchBarContext.getVendors(newValue)
          }
          if (this.currentSource.inputType === 'remoteOffice') {
            this.searchBarContext.getRemoteOffices(newValue)
          }
          if (this.currentSource.inputType === 'company') {
            this.searchBarContext.getCompanies(newValue)
          }
          if (this.currentSource.inputType === 'ssoConfig') {
            this.searchBarContext.getSsoConfigs(newValue)
          }
          if (this.currentSource.inputType === 'template') {
            this.searchBarContext.getTemplates(
              this.$constants.REQUEST,
              newValue
            )
          }
          if (this.currentSource.inputType === 'deploymentPolicy') {
            this.searchBarContext.getDeploymentPolicy(newValue)
          }
          if (this.currentSource.vuexOptionKey) {
            this.searchBarContext.getArchivedOptions(
              this.value.value,
              this.currentSource.vuexOptionKey
            )
          }
          if (this.currentSource.inputType === 'manufacturer') {
            this.searchBarContext.getManufacturers(newValue)
          }
          if (this.currentSource.inputType === 'poller') {
            this.searchBarContext.getPollers(newValue)
          }
        }
      },
    },
  },
  created() {
    if (!this.hasValue) {
      this.isOpen = true
    }
  },
  methods: {
    handleToggle() {
      if (this.isOpen && this.hasValue) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
    transformValue(value) {
      if (
        [
          'category',
          'location',
          'assetType',
          'dependent',
          'department',
        ].indexOf(this.currentSource.inputType) >= 0
      ) {
        const flatArray = flattenRecursive(this.sourceOptions)
        const v = Array.isArray(this.value.value)
          ? this.value.value
          : [this.value.value]
        return (this.additionalOption || [])
          .concat(flatArray)
          .filter((o) => v.indexOf(o.key || o.id) >= 0)
          .map((o) => o.text || o.label || o.name)
          .join(', ')
      }
      if (
        [
          'dropdown',
          'product',
          'service_catalog',
          'vendor',
          'requester',
          'remoteOffice',
          'deploymentPolicy',
          'template',
          'company',
          'ssoConfig',
          'approvalModuleSelector',
          'poller',
        ].indexOf(this.currentSource.inputType) >= 0
      ) {
        if (this.currentSource.valueType === 'boolean') {
          return this.sourceOptions
            .filter(
              (o) => `${this.value.value}`.indexOf(`${o.key}` || `${o.id}`) >= 0
            )
            .map((o) => o.text || o.label || o.name)
            .join(', ')
        }
        const value = Array.isArray(this.value.value)
          ? this.value.value
          : [this.value.value]
        return (this.additionalOption || [])
          .concat(this.sourceOptions)
          .filter((o) => (value || []).indexOf(o.key || o.id) >= 0)
          .map((o) => o.text || o.label || o.name)
          .join(', ')
      }
      if (this.currentSource.inputType === 'manufacturer') {
        return (this.additionalOption || [])
          .concat(this.sourceOptions)
          .filter((o) => (value || []).indexOf(o.key || o.id) >= 0)
          .map((o) => o.displayName)
          .join(', ')
      }
      if (Array.isArray(value)) {
        return value.map((v) => v.text || v.label || v.name || v).join(', ')
      }
      if (['date', 'dateTime'].indexOf(this.currentSource.inputType) >= 0) {
        if (durationOperators.includes(this.value.operator.value)) {
          return this.$options.filters.daytime(value)
        }
        return this.$options.filters.datetime(
          value,
          undefined,
          this.currentSource.inputType === 'dateTime'
        )
      }
      if (
        ['createdTime', 'dueBy', 'createdDate'].indexOf(
          this.currentSource.inputType
        ) >= 0
      ) {
        if (/^[a-z]+/.test(value)) {
          return this.$t(value)
        } else {
          if (durationOperators.includes(this.value.operator.value)) {
            return this.$options.filters.daytime(value)
          }
          return this.$options.filters.datetime(value, undefined, false)
        }
      }
      if (this.currentSource.inputType === 'boolean') {
        return this.value.value === true ? this.$t('yes') : this.$t('no')
      }
      if (
        this.currentSource.inputType === 'number' &&
        this.currentSource.allowDecimal
      ) {
        return (value || 0).toFixed(2)
      }
      if (
        this.currentSource.inputType === 'bytes' &&
        [
          'ComputerPropertyGroup.diskSize',
          'ComputerPropertyGroup.memorySize',
          'RamPropertyGroup.totalSize',
        ].indexOf(this.currentSource.paramName) >= 0
      ) {
        const v = Bytes(value, 'gb', false, true)
        return v
      }
      if (
        this.currentSource.inputType === 'hz' &&
        this.currentSource.paramName === 'ComputerPropertyGroup.cpuSpeed'
      ) {
        const v = Hertz(value, 'ghz')
        return v
      }
      return value
    },
    handleRemove() {
      if (this.hasValue) {
        this.$emit('remove')
        return
      }
      this.$emit('cancel')
    },
    cancelSelection() {
      this.$emit('cancel')
    },
    handleValueSelected({ operator, value, toValue, sourceModel }) {
      this.$emit('change', {
        displayName: this.currentSource.name,
        paramName: this.currentSource.paramName,
        valueType: this.currentSource.valueType,
        paramType: this.currentSource.paramType,
        operator,
        value,
        toValue,
        // add left operand additional params for dependentField is true
        ...(this.currentSource.inputType === 'dependent' ||
        this.currentSource.customFieldType === 'dependent'
          ? { leftOperandAdditionalParams: { dependentField: true } }
          : {}),
        ...(this.value.qualContext
          ? { qualContext: this.value.qualContext }
          : {}),
        ...(this.value.description
          ? { description: this.value.description }
          : {}),
        type: this.currentSource.type,
        sourceModel,
      })
      if (this.hasValue) {
        this.isOpen = false
        // close popover after value is updated
        // this.$nextTick(() => {
        //   this.$refs.popover.hide()
        // })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.@{ant-prefix}-tag {
  .tag-content {
    max-width: 650px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
